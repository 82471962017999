import React from "react"
import Layout from "../../components/common/layout"
import Main from "../../components/common/main"
import SEO from "../../components/common/seo"

const intro = (
  <>
    <div className="vb-150" />
    <div className="container line-container d-flex">
      <div className="col px-0">
        <div className="row">
          <div className="col-lg-8">
            <h1 className="display-2  text-white">
              Consulting
              <span>Re-engineering, DevOps, Process</span>
            </h1>
          </div>
        </div>
      </div>
    </div>
    <div className="pt-100" />
  </>
);

const body = (
  <section className="section bg-secondary">
        <div className="container">
            <div className="row row-grid">
                <div className="col-md-6">
                    <div className="card bg-gradient-default shadow border-0">
                        <img src="https://res.cloudinary.com/polyglots-cdn/image/upload/v1567714879/polyglots/site/ideas.jpg" alt="ideas" className="card-img-top" style={{backgroundColor: 'aliceblue'}} />
                        <blockquote className="card-blockquote">
                            <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583 95" className="svg-bg">
                            <polygon points="0,42 583,95 0,95" className="fill-default" />
                            <polygon points="0,42 583,95 583,0 0,95" opacity=".2" className="fill-primary" />
                            </svg>
                            <h4 className="display-3 font-weight-bold text-white">Talent Catalysts</h4>
                            <p className="lead text-italic text-white">We believe consulting is not just advice and guidance, but enriching the team talent.</p>
                            <p className="lead text-italic text-white">As software development is a collaborative and multi-step process not just limited to writing code.</p>
                        </blockquote>
                    </div>
                </div>
                <div className="col-md-6">
                  <div className="pl-md-5">
                      <h6>
                        We practice the following to create solutions that can be easily transformed and adjusted for future needs.
                      </h6>
                      <h6>If implemented early on, these practices have a compounding benefit for the people and the product.</h6>
                      <h5 className="mt-5">Re-engineering and DevOps</h5>
                      <ul className="mt-2">
                        <li className="py-2">
                            <h6 className="mb-0">Micro Frontends & Microservices</h6>
                        </li>
                        <li className="py-2">
                            <h6 className="mb-0">Cloud Native Architecture</h6>
                        </li>
                        <li className="py-2">
                            <h6 className="mb-0">Continuous Integration and Deployment</h6>
                        </li>
                      </ul>
                      <hr/>
                      <h5 className="mt-5">Tools and Process engineering</h5>
                      <ul className="mt-2">
                        <li className="py-2">
                            <h6 className="mb-0">Private NPM, Nexus, Artifactory</h6>
                        </li>
                        <li className="py-2">
                            <h6 className="mb-0">SCM best practices: Git, Gitlab, BitBucket</h6>
                        </li>
                        <li className="py-2">
                            <h6 className="mb-0">Project Planning</h6>
                        </li>
                      </ul>
                  </div>
                </div>
            </div>
        </div>
    </section>
);

const Consulting = props => {
  return (
    <Layout>
      <SEO title="Consulting" />
      <Main lineColor="line-default">{intro}</Main>
      {body}
    </Layout>
  )
}

Consulting.propTypes = {}

export default Consulting
